<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
    <div v-for="(content,index) in contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{content.text}}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'text2'">
        <el-col>
          <p class="demo-text2" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'author'">
        <el-col>
          <p class="author-text2" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'secondaryTitle2'">
        <el-col class="secondaryTitle2">
          <p class="secondaryTitle2" v-html="content.text"></p>
        </el-col>
      </el-row>


      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle3">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div  v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "awards",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Awards',
      contents:[
      // {
      //     type:'primaryTitle',
      //     text:'Conference Awards'
      //   },
        {
          type:'primaryTitle',
          text:'Best Paper Award'
        },
        

        {
          type:'secondaryTitle',
          text:'<b>Video Background Music Generation with Controllable Music Transformer</b>'
        },
        {
          type:'text',
          text:''
        },
        
        {
          type:'secondaryTitle2',
          text:'<b>Shangzhe Di</b> (Beihang University, China)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Zeren Jiang</b> (Beihang University, China)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Si Liu</b> (Beihang University, China)*'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Zhaokai Wang</b> (Beihang University, China)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Leyan Zhu</b> (Beihang University, China)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Zexin He</b> (Beihang University, China)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Hongming Liu</b> (Charterhouse School, United Kingdom)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Shuicheng Yan</b> (Sea AI Labs, Singapore)'
        },

       
        {
          type:'primaryTitle',
          text:'Best Student Paper Award'
        },
        {
          type:'secondaryTitle',
          text:'<b>aBio: Active Bi-Olfactory Display Using Subwoofers for Virtual Reality</b>'
        },
        {
          type:'text',
          text:''
        },
        
        {
          type:'secondaryTitle2',
          text:'<b>You-Yang Hu</b> (NTU)*'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Yao-Fu Jan</b> (NTU)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Kuan-Wei Tseng</b> (NTU)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>You-Shin Tsai</b> (NTU)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Hung-Ming Sung</b> (NTU)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Jin-Yao Lin</b> (TNNUA)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Yi-Ping Hung</b> (NTU)'
        },


        {
          type:'primaryTitle',
          text:'Best Demo Award'
        },
        {
          type:'secondaryTitle',
          text:'<b>ViDA-MAN: Visual Dialog with Digital Humans</b>'
        },
        {
          type:'text',
          text:''
        },
        {
          type:'secondaryTitle2',
          text:'<b>Tong Shen</b> (JD AI Research, China)*'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Jiawei Zuo</b> (JD AI Research, China)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Fan Shi</b> (JD AI Research, China)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Jin Zhang</b> (Migu, China)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Liqin Jiang</b> (Migu, China)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Meng Chen</b> (JD AI Research, China)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Zhengchen Zhang</b> (JD AI Research, China)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Wei Zhang</b> (JD AI Research, China)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Xiaodong He</b> (JD AI Research, China)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Tao Mei</b> (JD AI Research, China)'
        },


         {
          type:'primaryTitle',
          text:'Best Open Source Award'
        },
        {
          type:'secondaryTitle',
          text:'<b>X-modaler: A Versatile and High-performance Codebase for Cross-modal Analytics</b>'
        },
        {
          type:'text',
          text:''
        },
        {
          type:'secondaryTitle2',
          text:'<b>Yehao Li</b> (Sun Yat-Sen University, China)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Yingwei Pan</b> (JD AI Research, China)* '
        },
        {
          type:'secondaryTitle2',
          text:'<b>Jingwen Chen</b> (Sun Yat-set University, China)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Ting Yao</b> (JD AI Research, China)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Tao Mei</b> (JD AI Research, China)'
        },


        {
          type:'primaryTitle',
          text:'SIGMM Outstanding Technical Achievement Award'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Jiebo Luo</b> (University of Rochester, USA)'
        },
        {
          type:'text',
          text:''
        },
        {
          type:'author',
          text:''
        },


        {
          type:'primaryTitle',
          text:'SIGMM Rising Star Award'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Jingkuan Song</b> (University of Electronic Science and Technology of China, China)'
        },
        {
          type:'text',
          text:''
        },
        {
          type:'author',
          text:''
        },


        {
          type:'primaryTitle',
          text:'SIGMM Test of Time Paper Award'
        },
        {
          type:'secondaryTitle',
          text:"<b>Dynamic Adaptive Streaming over HTTP ‐ Standards and Design Principle</b> "
        },
        {
          type:'text2',
          text:"In Proceedings of the 2nd ACM Conference on Multimedia Systems, MMSys'11, pp. 133‐144, 2011."
        },
        {
          type:'secondaryTitle2',
          text:'<b>Thomas Stockhammer</b> (Qualcomm Inc., Germany)'
        },



        {
          type:'primaryTitle',
          text:'SIGMM Outstanding PhD Thesis Award'
        },
        {
          type:'secondaryTitle',
          text:'<b>Video Adaptation for High-Quality Content Delivery</b>'
        },
        // {
        //   type:'text',
        //   text:''
        // },
        {
          type:'secondaryTitle2',
          text:'<b>Kevin Spiteri</b> (University of Massachusetts Amherst, USA)'
        },

        {
          type:'primaryTitle',
          text:'2021 NICHOLAS GEORGANAS ACM TOMM Best Paper Award'
          // text:'ACM TOMM Best Paper Award'
        },
        {
          type:'secondaryTitle',
          text:'<b>Do Users Behave Similarly in VR? Investigation of the User Influence on the System Design</b>'
        },
        {
          type:'text2',
          text:"In ACM TOMM Volume 16, Issue 2, June 2020, Article No.46, pp 1–26."
        },
        {
          type:'secondaryTitle2',
          text:'<b>Silvia Rossi</b> (University College London, UK)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Cagri Ozcinar</b> (Trinity College Dublin, Ireland)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Aljosa Smolic</b> (Trinity College Dublin, Ireland)'
        },
        {
          type:'secondaryTitle2',
          text:'<b>Laura Toni</b> (University College London, UK)'
        },



      ],
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2021 Awards'
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";
</style>